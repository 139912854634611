






































































import Vue from "vue";
import * as models from "@/models";

export default {
  name: "seekerVideoRecordingGuide",
  props: {
    // seekerProfiles: {
    //   type: Array,
    //   default: () => []
    // },
    value: {
      type: Boolean,
      default: false
    }
    // dark: {
    //   type: Boolean, //Object as () => Boolean | String,
    //   required: false,
    //   default: false
    // }
  },
  data: () => ({}),
  created: function() {},
  methods: {
    close: function() {
      this.$emit("input", false);
    },
    ready: function() {
      this.$emit("input", false);
      this.$emit("ready");
    }
  },
  computed: {}
};
