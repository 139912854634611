



































import Vue from "vue";
import jobCard from "@/components/jobs/jobCard.vue";
import * as models from "@/models";

export default {
  name: "jobCardsList",
  props: {
    jobPosts: {
      type: Array,
      default: () => []
    },
    metaVisible: {
      type: Boolean,
      default: false,
      required: false
    },
    applyVisible: {
      type: Boolean,
      default: true
    },
    bookmarked: {
      type: Boolean,
      default: false,
      required: false
    },
    shareVisible: {
      type: Boolean,
      default: false,
      required: false
    },
    bookmarkVisible: {
      type: Boolean,
      default: true,
      required: false
    },
    dark: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    }
  },
  data: () => ({}),
  created: function() {},
  methods: {
    apply: function(jp: models.jobPostExtended) {
      this.$emit("apply", jp);
    },
    bookmark: function(jp: models.jobPostExtended) {
      this.$emit("bookmark", jp);
    },
    cardClick: function(jp: models.jobPostExtended) {
      this.$emit("card-click", jp);
    }
  },
  computed: {
    computedJobPosts: function(): models.jobPostExtended[] {
      // console.log(this.jobPosts);
      return this.jobPosts;
    },
    darkMode: function(): boolean {
      return !(this.dark === undefined || this.dark == false);
    }
  },
  components: { jobCard: jobCard }
};
