






import Vue from "vue";
export default {
  name: "hiddenFileInput",
  props: ["accept"],
  computed: {
    inputElm: function(): HTMLInputElement {
      return <any>this.$refs.fileInput;
    }
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {
    // this.inputElm.click();
  },
  methods: {
    requestFile: function() {
      this.inputElm.click();
    },
    onFileChange: function($event: any) {
      const files = $event.target.files || $event.dataTransfer.files;
      if (files.length == 0) {
        // CANT REACH HERE:
        // this.$emit("fileSelectCancelled");
        // console.log("nop");
      } else {
        this.$emit("fileSelected", files[0]);
      }
    }
  }
};
