var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    {
      attrs: {
        "xis-parent-child": "",
        "xparent-class": "dynamicGradiantBackground"
      }
    },
    [
      _c("m-toolbar", {
        staticClass: "elevation-0 transparent",
        attrs: {
          slot: "toolbar",
          "menu-button": "",
          "text-color": "primary--text xtext--lighten-4",
          title: _vm.title
        },
        slot: "toolbar"
      }),
      _c("hidden-file-input", {
        ref: "videoInput",
        attrs: { accept: "video/*" },
        on: {
          fileSelected: _vm.videoSelected,
          fileSelectCancelled: _vm.videoSelectCancelled
        }
      }),
      !_vm.editingOp.isEditing
        ? [
            _vm.VideoViewStatus == "loading"
              ? [
                  _c("div", { staticClass: "sq" }, [
                    _c(
                      "span",
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              : _vm.VideoViewStatus == "upload"
              ? [
                  _c(
                    "v-responsive",
                    {
                      staticStyle: { "background-color": "#E0E0E0" },
                      attrs: { "aspect-ratio": 1 / 1 }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { xfluid: "", "fill-height": "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "justify-center": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "text-xs-center": "" } },
                                [
                                  !_vm.uploadOp.uploading
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "120px",
                                            color: "accent darken-1"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                movie\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.uploadOp.uploading
                                    ? _c(
                                        "v-progress-circular",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "22px"
                                          },
                                          attrs: {
                                            size: 100,
                                            width: 9,
                                            color: "primary",
                                            xindeterminate: "",
                                            value:
                                              _vm.uploadOp.uploadPercentage,
                                            rotate: -90
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.uploadOp.uploadPercentage
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("br"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        round: "",
                                        disabled: _vm.uploadOp.uploading,
                                        loading: _vm.uploadOp.uploading,
                                        color: "accent darken-1"
                                      },
                                      on: { click: _vm.showRecordingGuidelines }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Record My 1' Video\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "loader" },
                                          slot: "loader"
                                        },
                                        [_vm._v("Uploading...")]
                                      )
                                    ]
                                  ),
                                  _vm.profile &&
                                  _vm.profile.seekerVideos &&
                                  _vm.profile.seekerVideos.length > 0 &&
                                  !_vm.uploadOp.uploading
                                    ? [
                                        _c("br"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              flat: "",
                                              round: "",
                                              color: "accent darken-1"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.primaryVideo =
                                                  _vm.profile.seekerVideos[0]
                                              }
                                            }
                                          },
                                          [_vm._v("Cancel\n                ")]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm.VideoViewStatus == "isProcessing"
              ? [
                  _c(
                    "v-responsive",
                    {
                      staticClass: "primary lighten-3",
                      attrs: { "aspect-ratio": 1 / 1 }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { xfluid: "", "fill-height": "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "justify-center": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "text-xs-center": "" } },
                                [
                                  _c("h3", { staticClass: "fun-font" }, [
                                    _vm._v(
                                      "We are processing your video now, this may\n                take few minutes."
                                    )
                                  ]),
                                  _c("br"),
                                  _c("h3", { staticClass: "fun-font" }, [
                                    _vm._v(
                                      "Meanwhile, you can discover job openings.\n              "
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        round: "",
                                        color: "primary",
                                        dark: "",
                                        to: { name: "Seeker_Discover" }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("explore")
                                      ]),
                                      _vm._v("discover\n              ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm.VideoViewStatus == "video"
              ? [
                  _c("video-player", {
                    attrs: {
                      "poster-url": _vm.primaryVideo.video.vidThumbUriComputed,
                      "video-url": _vm.primaryVideo.video.vidUri
                    }
                  })
                ]
              : [_vm._v("THIS IS NOT SUPPOSED TI SHOW!")]
          ]
        : _vm._e(),
      _vm.profile && !_vm.editingOp.isEditing
        ? _c(
            "v-card",
            {
              staticClass: "elevation-0 white--text",
              attrs: { color: "accent darken-3" }
            },
            [
              _c(
                "v-card-actions",
                [
                  _vm.IsShareSupported
                    ? _c(
                        "v-btn",
                        {
                          attrs: { round: "", flat: "", dark: "" },
                          on: { click: _vm.shareSeekerProfile }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("share")
                          ]),
                          _vm._v(" SHARE\n\n      ")
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.IsShareSupported
                    ? _c(
                        "v-btn",
                        {
                          attrs: { round: "", flat: "", dark: "" },
                          on: { click: _vm.copySeekerProfileToClipboard }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("library_books")
                          ]),
                          _vm._v(" SHARE\n      ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { round: "", flat: "", dark: "" },
                      on: { click: _vm.showRecordingGuidelines }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("videocam")
                      ]),
                      _vm._v(" RECORD\n\n      ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "pa-4", attrs: { "grid-list-md": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _vm.profile && !_vm.editingOp.isEditing
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "right",
                          attrs: { icon: "" },
                          on: { click: _vm.editing_startEditing }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c("h2", { staticClass: "primary--text" }, [
                        _vm._v(_vm._s(_vm.profile.headline))
                      ]),
                      _c("h3", { staticClass: "accent--text text--darken-3" }, [
                        _vm._v(_vm._s(_vm.profile.industry.title))
                      ]),
                      _c(
                        "h3",
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.profile.firstname) +
                              "\n          " +
                              _vm._s(_vm.profile.lastname) +
                              "\n          "
                          ),
                          _vm.profile.city
                            ? _c("span", { staticClass: "subheading" }, [
                                _vm._v(
                                  "—\n            " +
                                    _vm._s(_vm.profile.city.name)
                                )
                              ])
                            : _vm._e(),
                          _c("br"),
                          _vm._l(_vm.profile.jobTypes, function(jt, idx) {
                            return _c("small", { key: jt }, [
                              _c("span", [_vm._v(_vm._s(jt))]),
                              idx + 1 < _vm.profile.jobTypes.length
                                ? _c("span", {
                                    domProps: { textContent: _vm._s(" | ") }
                                  })
                                : _vm._e()
                            ])
                          }),
                          _c("br"),
                          _vm._l(_vm.profile.languages, function(lang, idx) {
                            return _c(
                              "small",
                              {
                                key: lang.name,
                                staticClass: "accent--text text--darken-3"
                              },
                              [
                                _c("span", [_vm._v(_vm._s(lang.name))]),
                                idx + 1 < _vm.profile.languages.length
                                  ? _c("span", {
                                      domProps: { textContent: _vm._s(" | ") }
                                    })
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.profile.bio) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editingOp.isEditing
                ? [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("h2", [_vm._v("Edit Profile")])
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-form",
                          { ref: "editForm" },
                          [
                            _c(
                              "v-tabs",
                              {
                                attrs: {
                                  color: "transparent ",
                                  grow: "",
                                  "slider-color": "primary",
                                  "active-class": "grey lighten-2"
                                }
                              },
                              [
                                _c("v-tab", { attrs: { ripple: "" } }, [
                                  _vm._v("Personal")
                                ]),
                                _c("v-tab", { attrs: { ripple: "" } }, [
                                  _vm._v("Career")
                                ]),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            xcolor: "primary",
                                            label: "First Name",
                                            required: "",
                                            counter: 20,
                                            maxlength: "20",
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.maxCounter(20)
                                            ],
                                            "xpersistent-hint": ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile
                                                .firstname,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "firstname",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.firstname"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            xcolor: "white",
                                            counter: 20,
                                            maxlength: "20",
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.maxCounter(20)
                                            ],
                                            label: "Last Name",
                                            required: "",
                                            "xpersistent-hint": ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile
                                                .lastname,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "lastname",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.lastname"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("advanced-selector", {
                                          attrs: {
                                            items: _vm.countries,
                                            itemValueField: "code",
                                            itemTextField: "name",
                                            rules: [_vm.rules.required],
                                            loading: _vm.isLoadingCountries,
                                            title: "Current Country",
                                            required: "",
                                            grouped: "",
                                            xoutline: ""
                                          },
                                          model: {
                                            value: _vm.selectedCountry,
                                            callback: function($$v) {
                                              _vm.selectedCountry = $$v
                                            },
                                            expression: "selectedCountry"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("advanced-selector", {
                                          attrs: {
                                            disabled: !_vm.hasSelectedCountry,
                                            "persistent-hint": !_vm.hasSelectedCountry,
                                            hint: !_vm.hasSelectedCountry
                                              ? "To select a city, select a country first"
                                              : "",
                                            loading: _vm.isLoadingCities,
                                            items: _vm.cities,
                                            itemValueField: "id",
                                            itemTextField: "name",
                                            rules: [_vm.rules.required],
                                            title: "Current City",
                                            required: "",
                                            grouped: "",
                                            xoutline: ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile.city,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.city"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("advanced-selector", {
                                          attrs: {
                                            items: _vm.languages,
                                            loading: _vm.languages.length === 0,
                                            itemValueField: "code",
                                            itemTextField: "name",
                                            title: "Spoken Languages",
                                            allowAll: false,
                                            grouped: "",
                                            multiple: "",
                                            xoutline: ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile
                                                .languages,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "languages",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.languages"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("advanced-selector", {
                                          attrs: {
                                            items: _vm.industries,
                                            loading: _vm.isLoadingIndustries,
                                            itemValueField: "id",
                                            itemTextField: "title",
                                            title: "Industry",
                                            rules: [_vm.rules.required],
                                            required: "",
                                            xoutline: ""
                                          },
                                          model: {
                                            value: _vm.selectedIndustry,
                                            callback: function($$v) {
                                              _vm.selectedIndustry = $$v
                                            },
                                            expression: "selectedIndustry"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("advanced-selector", {
                                          attrs: {
                                            disabled: _vm.sectors.length === 0,
                                            "persistent-hint":
                                              _vm.sectors.length === 0,
                                            hint:
                                              _vm.sectors.length === 0
                                                ? "To select a sector, select an industry first"
                                                : "",
                                            items: _vm.sectors,
                                            loading: _vm.isLoadingIndustries,
                                            itemValueField: "id",
                                            itemTextField: "title",
                                            title: "Sector",
                                            rules: [_vm.rules.required],
                                            required: "",
                                            xoutline: ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile
                                                .industry,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "industry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.industry"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            xcolor: "white",
                                            label: "Headline",
                                            required: "",
                                            counter: 100,
                                            maxlength: "100",
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.maxCounter(100)
                                            ],
                                            hint: "Short, and to the point!",
                                            "xpersistent-hint": ""
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile
                                                .headline,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "headline",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.headline"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Bio",
                                            xrequired: "",
                                            counter: 300,
                                            maxlength: "300",
                                            "auto-grow": "",
                                            rules: [_vm.rules.maxCounter(300)],
                                            hint:
                                              "Describe your career in few words"
                                          },
                                          model: {
                                            value:
                                              _vm.editingOp.editedProfile.bio,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingOp.editedProfile,
                                                "bio",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editingOp.editedProfile.bio"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                xcolor: "white",
                                                items: [
                                                  "Full-Time",
                                                  "Part-Time",
                                                  "Contract/Freelance"
                                                ],
                                                label: "Job Type",
                                                xrules:
                                                  "[(v)=> (!!editingOp.editedProfile.jobTypes && editingOp.editedProfile.jobTypes.length>0) || 'Required']",
                                                multiple: "",
                                                xrequired: "",
                                                dense: "",
                                                "menu-props": { auto: true },
                                                "append-outer-icon": "close",
                                                "xclick:append-outer": ""
                                              },
                                              model: {
                                                value:
                                                  _vm.editingOp.editedProfile
                                                    .jobTypes,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.editingOp.editedProfile,
                                                    "jobTypes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editingOp.editedProfile.jobTypes"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              color: "accent darken-3",
                              round: "",
                              large: "",
                              loading: _vm.editingOp.isSaving,
                              disabled: _vm.editingOp.isSaving
                            },
                            on: { click: _vm.editing_save }
                          },
                          [_vm._v("Save")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { staticClass: "text-xs-center", attrs: { xs12: "" } },
                      [
                        !_vm.editingOp.isSaving
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  blockx: "",
                                  color: "accent darken-3",
                                  flat: "",
                                  round: "",
                                  large: ""
                                },
                                on: { click: _vm.editing_cancelEditing }
                              },
                              [_vm._v("\n            Cancel\n          ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("seeker-video-recording-guide", {
        on: { ready: _vm.startNewVideoUpload },
        model: {
          value: _vm.recordingGuide,
          callback: function($$v) {
            _vm.recordingGuide = $$v
          },
          expression: "recordingGuide"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }