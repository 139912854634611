var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          attrs: { xxfocus: "activatorClicked" },
          on: { click: _vm.activatorClicked }
        },
        [
          _vm._t("activator", null, {
            selectedItemsText: _vm.selectedItemsText
          })
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            persistent: true,
            "max-width": _vm.maxWidth
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { light: "" } },
            [
              _c("v-card-title", {
                staticClass: "primary white--text",
                domProps: { textContent: _vm._s(_vm.title) }
              }),
              _c(
                "div",
                { staticClass: "mx-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Filter",
                      "append-icon": _vm.filter ? "close" : null,
                      "append-outer-icon": "filter_list",
                      "hide-details": "",
                      xoutline: ""
                    },
                    on: {
                      "click:append": function() {
                        _vm.filter = null
                      }
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pa-0", style: { "min-height": _vm.maxWidth } },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm._l(_vm.computedItems, function(item, index) {
                        return [
                          _c(
                            "v-list-tile",
                            {
                              key: index,
                              attrs: { avatar: _vm.multiple, ripple: "" },
                              on: {
                                click: function($event) {
                                  return _vm.selectItem(item)
                                }
                              }
                            },
                            [
                              _vm.multiple
                                ? _c(
                                    "v-list-tile-avatar",
                                    [
                                      _c("v-icon", {
                                        attrs: { color: "accent darken-2" },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.selected
                                              ? "check_box"
                                              : "check_box_outline_blank"
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", {
                                    domProps: {
                                      textContent: _vm._s(item.itemText)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          index + 1 < _vm.items.length
                            ? _c("v-divider", { key: "d-" + index })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("v-divider"),
              _vm.selectedItemsText
                ? _c(
                    "v-card-actions",
                    {
                      staticClass: "font-weight-light",
                      staticStyle: { padding: "8px" }
                    },
                    [
                      _c("small", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(_vm.selectedItemsText))
                      ])
                    ]
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-card-actions",
                {
                  staticClass: "grey lighten-3",
                  staticStyle: { padding: "8px" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "default", flat: "" },
                      on: { click: _vm.closeMe }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm.multiple
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", round: "" },
                          on: { click: _vm.updateValue }
                        },
                        [_vm._v("Done")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }