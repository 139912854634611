








































































import Vue from "vue";

export default {
  name: "modelSelector",
  props: {
    value: {
      // required: false
      default:null
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false
    },
    items: {
      required: false,
      default: () => []
    },
    title: {
      required: false,
      type: String,
      default: "Select..."
    },
    itemText: {
      required: true,
      type: String,
      default: "text"
    },
    itemValue: {
      required: true,
      type: String,
      default: "text"
    },
    maxWidth: {
      required: false,
      type: String,
      default: "300px"
    }
  },
  data: () => ({
    dialog: false,
    resolver: null,
    selectedItems: [],
    filter: null
    // internalOrgs: null
  }),
  watch: {
    value: function(newValue) {
      console.log('hit');
      
      this.readSelectedItemsFromInputValue(newValue);
    }
  },
  created: function() {
    //  this.orgs[0].
  },
  mounted: function() {
    // if (this.orgs) this.internalOrgs = this.orgs;
    this.readSelectedItemsFromInputValue();
  },
  methods: {
    activatorClicked: function() {
      this.readSelectedItemsFromInputValue();
      this.dialog = true;
    },
    readSelectedItemsFromInputValue: function(value) {
      let inputValue = value || this.value;
      if (inputValue && this.multiple) {
        this.selectedItems = this.items.filter(sourceItem =>
          inputValue.some(
            item => item[this.itemValue] == sourceItem[this.itemValue]
          )
        );
      }
    },
    selectItem: function(item) {
      if (this.multiple) {
        if (
          this.selectedItems.some(si => si[this.itemValue] == item.itemValue)
        ) {
          //remove

          this.selectedItems.splice(
            this.selectedItems.findIndex(
              si => si[this.itemValue] == item.itemValue
            ),
            1
          );
        } else {
          this.selectedItems.push(item.original);
        }
      } else {
        this.selectedItems = [item];
        this.updateValue();
      }
    },
    closeMe: function() {
      this.dialog = false;
    },
    updateValue() {
      this.$emit(
        "input",
        this.multiple ? this.selectedItems : this.selectedItems[0]
      );
      this.closeMe();
    }
  },
  computed: {
    computedItems: function() {
      if (!this.items || this.items.length == 0) return [];
      let data = this.items.map(item => ({
        itemText: item[this.itemText],
        itemValue: item[this.itemValue],
        original: item,
        selected: this.selectedItems.some(
          si => si[this.itemValue] == item[this.itemValue]
        )
      }));
      if (this.filter) {
        return data.filter(item =>
          item.itemText.toLowerCase().includes(this.filter.toLowerCase())
        );
      } else {
        return data;
      }
    },
    selectedItemsText: function() {
      if (this.selectedItems.length == 0) return null;
      return this.selectedItems
        .map(item => item[this.itemText])
        .reverse()
        .join(", ");
    }
  }
};
