var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "grid-list-lg": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm._t("header"),
          _vm._l(_vm.computedJobPosts, function(jobPost) {
            return _c(
              "v-flex",
              _vm._b(
                { key: jobPost.id, attrs: { xs12: "" } },
                "v-flex",
                _vm.$attrs,
                false
              ),
              [
                _c(
                  "job-card",
                  {
                    attrs: {
                      "job-post": jobPost,
                      "bookmark-visible": _vm.bookmarkVisible,
                      "share-visible": _vm.shareVisible,
                      bookmarked: _vm.bookmarked,
                      "apply-visible": _vm.applyVisible,
                      "meta-visible": _vm.metaVisible,
                      dark: _vm.dark
                    },
                    on: {
                      bookmark: function($event) {
                        return _vm.bookmark(jobPost)
                      },
                      apply: function($event) {
                        return _vm.apply(jobPost)
                      },
                      "card-click": function($event) {
                        return _vm.cardClick(jobPost)
                      }
                    }
                  },
                  [
                    _vm._t("card-actions", null, {
                      slot: "card-actions",
                      xslotScope: "props",
                      jobPost: jobPost
                    }),
                    _vm._t("main-action", null, {
                      slot: "main-action",
                      xslotScope: "props",
                      jobPost: jobPost
                    })
                  ],
                  2
                )
              ],
              1
            )
          }),
          _vm._t("footer")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }