var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            transition: "dialog-bottom-transition",
            persistent: ""
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancelAction()
            }
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.close($event)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Record your pitch")])
                ],
                1
              ),
              _c(
                "v-list",
                { attrs: { "three-line": "", subheader: "" } },
                [
                  _c("v-subheader", [_vm._v("Guidelines")]),
                  _c("v-subheader", [
                    _c("div", { staticClass: "font-weight-light" }, [
                      _vm._v(
                        "Take a moment to learn how to make a stunning video."
                      )
                    ])
                  ]),
                  _c(
                    "v-list-tile",
                    {
                      attrs: { avatar: "" },
                      on: {
                        click: function($event) {
                          return _vm.showInfoSlides(2)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "accent darken-2" } },
                            [_vm._v("filter_1")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        { staticClass: "accent--text text--darken-2" },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Video Production Guidelines")
                          ]),
                          _c(
                            "v-list-tile-sub-title",
                            { staticClass: "accent--text text--darken-1" },
                            [_vm._v("Learn how to record a presentable video.")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    {
                      attrs: { avatar: "" },
                      on: {
                        click: function($event) {
                          return _vm.showInfoSlides(1)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "accent darken-2" } },
                            [_vm._v("filter_2")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            { staticClass: "accent--text text--darken-2" },
                            [_vm._v("Pitch Content Guidelines")]
                          ),
                          _c(
                            "v-list-tile-sub-title",
                            { staticClass: "accent--text text--darken-1" },
                            [
                              _vm._v(
                                "Find out what you should talk about in your video; how to make an attractive pitch."
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                { attrs: { "three-line": "", subheader: "" } },
                [
                  _c("v-subheader", [_vm._v("Ready?")]),
                  _c(
                    "v-list-tile",
                    { attrs: { avatar: "" }, on: { click: _vm.ready } },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("filter_3")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        { staticClass: "primary--text" },
                        [
                          _c("v-list-tile-title", [_vm._v("Start Recording")]),
                          _c(
                            "v-list-tile-sub-title",
                            { staticClass: "primary--text text--lighten-3" },
                            [
                              _vm._v(
                                "Record and upload your 1 minute video pitch. Don't worry, you can record and upload as many videos as you need."
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }