


























































































































































































































































































































































































































































































































import Vue from "vue";
import * as models from "@/models";
import { G, util, rules } from "@/globals";
import { jobs } from "@/code/api.jobs.seekers";
import { lookups } from "@/code/api.lookups";
import hiddenFileInput from "@/components/hidden-file-input.vue";
import videoPlayer from "@/components/videoPlayer.vue";
import { videoStatus } from "@/models";
import { Tracking } from "@/code/tracking";
import seekerVideoRecordingGuide from "@/components/seekers/seekerVideoRecordingGuide.vue";
import modalSelector from "@/components/modalSelector.vue";

export default {
  name: "Seekers_MyProfile",
  components: {
    modalSelector,
    hiddenFileInput,
    videoPlayer,
    seekerVideoRecordingGuide
  },
  created: function() {
    G.data.customTitle = "...";
  },
  data: () => ({
    languages: [],
    initialLoadCompleted: false,

    profile: <models.seekerProfile>{
      headline: "loading...",
      industry: {
        title: ""
      }
    },
    primaryVideo: null,
    editingOp: {
      isEditing: false,
      editedProfile: null as models.seekerProfile,
      isSaving: false
    },
    countries: <models.country[]>[],
    cities: <models.city[]>[],
    industries: <models.industry[]>[],
    selectedCountry: <models.country>null,
    selectedIndustry: <models.industry>null,
    isLoadingCountries: false,
    isLoadingCities: false,
    isLoadingCitiesFor: null,
    isLoadingIndustries: false,

    rules: {
      required: rules.required,
      maxCounter: rules.maxCounter
    },
    recordingGuide: false,
    uploadOp: {
      uploading: false,
      uploadPercentage: 0,
      isProcessing: false,
      processingStages: [
        {
          stage: "Warming Up",
          subtitle: "Our robots are coming",
          inProgress: true,
          completed: false
        },
        {
          stage: "Queued",
          subtitle: "Your video is in queue",
          inProgress: false,
          completed: false
        },
        {
          stage: "Processing Video",
          subtitle: "This may take a while, sit tight",
          inProgress: false,
          completed: false
        },
        {
          stage: "Finalizing",
          subtitle: "Your video is here, yeeey",
          inProgress: false,
          completed: false
        }
      ],
      checkProgressIntervalHandler: null
    }
  }),
  methods: {
    shareSeekerProfile: function() {
      util.share(
        this.profile.headline,
        "http://meinone-sharer.azurewebsites.net/seeker.aspx?id=" +
          this.profile.id
      );
      Tracking.seeker.share(this.profile.id);
    },
    copySeekerProfileToClipboard: function() {
      let linkText =
        this.profile.headline +
        " | MeInOne Link: http://meinone-sharer.azurewebsites.net/seeker.aspx?id=" +
        this.profile.id;
      this.$clipboard(linkText);
      G.showSnackbar("Profil link copied to clipboard", "success");
      Tracking.seeker.copyLink(this.profile.id);
    },
    showRecordingGuidelines: async function() {
      this.recordingGuide = true;
    },
    startNewVideoUpload: async function() {
      if (!this.seekerHasVideos)
        Tracking.video.startRecordingFirstVideo(G.data.currentLogin.id);
      this.uploadOp.isProcessing = false;
      this.uploadOp.uploading = false;
      (<any>this.$refs.videoInput).requestFile();

      // G.confirmDialog
      //   .open(
      //     `<div style="line-height: 2;text-align: left;">
      //     <h2>Before You Begin</h2>
      //     <ul>
      //     <li>Stay in the center of the frame, your video will be squared (like instagram).</li>
      //     <li>Shoot with clear audio in a well-let, quiet space.</li>
      //     <li>Talk market problem, solution and your experience, why you're the right pick.</li>
      //     </ul>
      //     <strong class="primary--text">
      //     Oh, and don't forget to smile.</strong>
      //     </div>
      //      `,
      //     "One minute only to leave the right impression, make it count ;)",
      //     "Record Later :(",
      //     `READY :D`, // (${jobPost.title})
      //     "warning darken-1",
      //     "primary"
      //   )
      //   .then(confirm => {
      //     if (confirm) {
      //       if (!this.seekerHasVideos)
      //         Tracking.video.startRecordingFirstVideo(G.data.currentLogin.id);
      //       this.primaryVideo = null;
      //       this.uploadOp.isProcessing = false;
      //       this.uploadOp.uploading = false;
      //       (<any>this.$refs.videoInput).requestFile();
      //     }
      //   });
    },
    loadProfile: async function(): Promise<void> {
      G.log.action("Seeker.MyProfile.loadProfile", "Getting Profile", true);

      jobs.seekers.profiles
        .getProfile(G.data.currentLogin.id, true)
        .then(profile => {
          G.log.action("Seeker.MyProfile.loadProfile", profile, true);
          this.profile = profile;

          let vStatus = this.getProfileVideoStatus(profile.seekerVideos);

          if (vStatus == videoStatus.Undefined) {
            //= NO VIDEOS
            return;
          } else if (vStatus == videoStatus.Ready) {
            //= VIDEO READY
            this.primaryVideo = profile.seekerVideos[0];
          } else {
            //= VIDEO IS PROCESSING
            this.uploadOp.isProcessing = true;
            this.startProgressMonitor(true);
          }
        })
        .catch(err => {
          G.log.error("Seeker.MyProfile.loadProfile profiles.getProfile", err);
        })
        .finally(() => {
          this.initialLoadCompleted = true;
          return;
        });
    },
    handleUploadProgress(percentage: number) {
      this.uploadOp.uploadPercentage = percentage;
    },
    videoSelected(file: File) {
      this.primaryVideo = null;
      G.log.action("home.videoSelected", "start uploading", true);
      this.uploadOp.uploading = true;
      if (!this.seekerHasVideos)
        Tracking.video.finishRecordingFirstVideo(G.data.currentLogin.id);
      Tracking.video.beginUpload(G.data.currentLogin.id, file.size);

      jobs.seekers.videos
        .upload(file, this.handleUploadProgress)
        .then(v => {
          Tracking.video.completeUpload(G.data.currentLogin.id);

          G.log.action("home.videoSelected", {
            seekerVideoUploaded: v
          });
          this.uploadOp.isProcessing = true;
          this.startProgressMonitor(true);
        })
        .catch(err => {
          G.log.error("home.videoSelected", err);
          this.restoreVideo();
        })
        .finally(() => {
          this.uploadOp.uploading = false;
        });
    },
    videoSelectCancelled() {
      if (
        this.profile &&
        this.profile.seekerVideos &&
        this.profile.seekerVideos.length > 0
      )
        this.primaryVideo = this.profile.seekerVideos[0];
    },
    restoreVideo() {
      this.videoSelectCancelled();
    },
    getProfileVideoStatus(svs: models.seekerVideo[]): videoStatus {
      if (!svs || svs.length == 0) return models.videoStatus.Undefined;
      else {
        return svs[0].video.vidStatusId;
      }
    },
    async startProgressMonitor(loop: boolean) {
      let gettingVideos = false; // <-- Lock to prevent parallel calls
      this.uploadOp.checkProgressIntervalHandler = setInterval(() => {
        if (gettingVideos) return;
        gettingVideos = true;
        jobs.seekers.videos
          .getMyVideos()
          .then(svs => {
            let vStatus = this.getProfileVideoStatus(svs);
            if (vStatus == videoStatus.Undefined) {
              //= NO VIDEOS
              return;
            } else if (vStatus == videoStatus.Ready) {
              //= VIDEO READY
              let sv = svs[0]; // First video, MUST change when allowing multi-videos :(
              this.primaryVideo = sv;
              this.uploadOp.isProcessing = false;

              clearInterval(this.uploadOp.checkProgressIntervalHandler);
            } else {
              //= VIDEO IS PROCESSING

              loop = true;
              this.uploadOp.isProcessing = true;

              if (vStatus == videoStatus.UploadedToStaging) {
                this.uploadOp.processingStages[0].completed = true;
                this.uploadOp.processingStages[1].inProgress = true;
              } else {
                this.uploadOp.processingStages[0].completed = true;
                this.uploadOp.processingStages[1].completed = true;
                this.uploadOp.processingStages[2].inProgress = true;
              }
            }
          })
          .catch(err => {
            G.log.error("Seeker.MyProfile.startProgressMonitor", err);
          })
          .finally(() => {
            if (!loop)
              clearInterval(this.uploadOp.checkProgressIntervalHandler);
            gettingVideos = false;
          });
      }, 3000);
    },
    editing_startEditing() {
      this.editingOp.editedProfile = {
        ...this.profile
      };
      this.editingOp.isEditing = true;
      lookups.languages_Cached().then(lngs => {
        this.languages = lngs;
      });
      this.fetchCountries();
      let city = this.editingOp.editedProfile.city;
      //load initial cities
      if (city) {
        this.fetchCities(city.countryCode);
      }
      this.fetchIndustries();
    },
    editing_cancelEditing() {
      if (
        this.profile &&
        this.profile.seekerVideos &&
        this.profile.seekerVideos.length > 0
      )
        this.primaryVideo = this.profile.seekerVideos[0];
      this.editingOp.isEditing = false;
    },
    editing_save() {
      let editForm: any = this.$refs.editForm;
      if (!editForm.validate()) return;
      //TODO: Validate
      this.editingOp.isSaving = true;
      G.log.action(
        "Seeker.MyProfile.vue.editing_save",
        "Started editing",
        true
      );

      jobs.seekers.profiles
        .update(this.editingOp.editedProfile)
        .then(profile => {
          this.profile = profile;
          this.editingOp.isEditing = false;
        })
        .catch(e => {
          G.log.error("Seeker.MyProfile.vue.editing_save", e);
        })
        .finally(() => {
          this.editingOp.isSaving = false;
        });
    },
    fetchCountries() {
      this.isLoadingCountries = true;
      lookups
        .countries()
        .then(countries => {
          this.countries = countries;
          let city = this.profile.city;
          this.selectedCountry = countries.find(
            country => country.code === city.countryCode
          );
        })
        .finally(() => (this.isLoadingCountries = false));
    },
    fetchCities(countryCode) {
      this.isLoadingCities = true;
      this.isLoadingCitiesFor = countryCode;
      lookups
        .cities(countryCode)
        .then(response => {
          this.cities = response.cities;
        })
        .finally(() => {
          this.isLoadingCities = false;
          this.isLoadingCitiesFor = null;
        });
    },
    fetchIndustries() {
      this.isLoadingIndustries = true;
      lookups
        .industries()
        .then(industries => {
          this.industries = industries;
          let sector = this.profile.industry;
          this.selectedIndustry = industries.find(
            industry => industry.id === sector.parent.id
          );
        })
        .finally(() => (this.isLoadingIndustries = false));
    }
  },
  computed: {
    title: function(): string {
      return this.profile ? this.profile.firstname : null;
    },
    WelcomeMessage: function() {
      let cl = G.data.currentLogin;
      if (cl) return "Hello " + cl.firstname;
      else return "hello stranger";
    },
    VideoViewStatus: function():
      | "loading"
      | "upload"
      | "isProcessing"
      | "video" {
      if (!this.initialLoadCompleted) return "loading";
      if (this.uploadOp.isProcessing) return "isProcessing";
      if (this.primaryVideo) return "video";
      return "upload";
    },
    IsShareSupported: () => util.isShareSupported,
    seekerHasVideos: function(): boolean {
      return (
        this.profile &&
        this.profile.seekerVideos &&
        this.profile.seekerVideos.length > 0
      );
    },
    hasSelectedCountry: function(): boolean {
      //assume that country selector is in single choice mode
      return !!this.selectedCountry;
    },
    sectors: function() {
      return this.selectedIndustry ? this.selectedIndustry.children : [];
    }
  },

  watch: {
    profile: {
      handler: function(newValue: models.seekerProfile) {
        if (!newValue) return;
        G.data.customTitle = newValue.firstname + " " + newValue.lastname;
      },
      deep: true
    },
    selectedCountry(country) {
      if (country) {
        let currentCity = this.editingOp.editedProfile.city;
        if (currentCity && currentCity.countryCode !== country.code) {
          this.editingOp.editedProfile.city = null;
          this.cities = [];
        }
        //prevent double loading of cities
        if (this.isLoadingCitiesFor !== country.code) {
          this.fetchCities(country.code);
        }
      }
    },
    selectedIndustry(industry) {
      if (industry) {
        let sector = this.editingOp.editedProfile.industry;
        if (sector && sector.parent.id !== industry.id) {
          this.editingOp.editedProfile.industry = null;
        }
      }
    }
  },
  mounted() {
    G.log.action("Seeker.MyProfile.mounted", "Getting Your Video", false);
    // this.startProgressMonitor(false);
    this.loadProfile();

    // jobs.seekers.videos
    //   .getMyVideos()
    //   .then(svs => {
    //     if (svs.length == 0) return;
    //     let sv = svs[0]; // First video, MUST change when allowing multi-videos :(
    //     if (sv.video.vidStatusId == 200) {
    //       this.primaryVideo = sv;
    //       G.log.action("Seeker.MyProfile.mounted", sv, false);
    //     }
    //   })
    //   .catch(err => {
    //     G.log.error("Seeker.MyProfile.mounted getMyVideos", err);
    //   })
    //   .finally(() => {});
  }
};
