






















































































































































































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { jobs } from "@/code/api.jobs.seekers";
import jobCardsList from "@/components/jobs/jobCardsList.vue";
import { lookups } from "@/code/api.lookups";
import MugenScroll from "vue-mugen-scroll";

export default {
  name: "Seeker_Discover",
  components: { jobCardsList, MugenScroll },

  data: () => ({
    extraOpen: false,
    jobPostsExtended: <models.paged<models.jobPostExtended>>{ items: [] },
    initializing: true,
    pageSize: 20,
    loadingMore: false,
    filter: {
      seekerParentIndustry: { title: "..." } as models.industry,
      selectedIndustry: null,
      lastUsedIndustry: null /*aaaa */,
      countries: null,
      cities: null,
      industries: null,
      sectors: null
    },
    filter_selectedCountries: [],
    filter_selectedCities: [],
    filter_selectedIndustries: [],
    filter_selectedSectors: []
  }),
  computed: {
    qsIndId: function(): number {
      let id = G.getQueryString(this.$route.query, "indId");
      if (!id) return null;
      if (isNaN(parseInt(id))) return null;
      return parseInt(id);
    },
    currentFilterTitle: function(): string {
      if (this.filter.lastUsedIndustry) {
        return this.filter.lastUsedIndustry.title;
      } else {
        return `All ${this.filter.seekerParentIndustry.title} Sectors`;
      }
    },
    // currentLogin: function() {
    //   return G.data.currentLogin;
    // },
    seekerHasVideos: function() {
      // return false;
      return G.data.currentLogin && G.data.currentLogin.seekerProfile.hasVideos;
    }
  },
  watch: {
    initializing: function(newValue: boolean) {
      newValue ? G.showLoading() : G.hideLoading();
    }
  },

  created: function() {
    G.showLoading();
    G.data.customTitle = "Discover";
    //=======
  },
  mounted: async function() {
    if (await this.prepareFilters()) {
      await this.loadJobPosts(0, this.pageSize, this.filter.lastUsedIndustry);
    } else {
      this.$router.push({
        name: "Login"
      });
    }
    this.initializing = false;
  },
  methods: {
    prepareFilters: async function(): Promise<boolean> {
      if (this.qsIndId) {
        // Use Query String
        let qsIndId: number = this.qsIndId;
        // No Sector Id?
        if (!qsIndId) {
          return false;
        } else {
          // this.filter.seekerParentIndustry =
          //   G.data.currentLogin.seekerProfile.industry.parent;

          return lookups.industries_Cached().then(inds => {
            let targetParent = inds.find(ind => ind.id == qsIndId);
            let targetIndustry = targetParent;

            if (!targetParent) {
              targetParent = inds.find(ind =>
                ind.children.some(sec => sec.id == qsIndId)
              );
              if (!targetParent) {
                // No Industry with this ID exists
                return false;
              }
              targetIndustry = targetParent.children.find(
                sec => sec.id == qsIndId
              );
            }

            if (!targetIndustry) {
              // Requested Sector is invalid
              return false;
            } else {
              // Requested Sector is VALID
              this.filter.seekerParentIndustry = targetParent;

              this.filter.sectors = targetParent.children;
              this.filter.selectedIndustry = targetIndustry;
              this.filter.lastUsedIndustry = targetIndustry;
              return true;
            }
          });
        }
      } else if (G.data.currentLogin) {
        // Use Current Login
        this.filter.seekerParentIndustry =
          G.data.currentLogin.seekerProfile.industry.parent;

        return lookups.industries_Cached().then(inds => {
          this.filter.sectors = inds.find(
            ind => ind.id == this.filter.seekerParentIndustry.id
          ).children;
          return true;
        });
      } else {
        return false;
      }
    },
    loadJobPosts: function(skip: number, take: number, ind?: models.industry) {
      if (!ind) ind = this.filter.seekerParentIndustry;

      let apiFunction = G.data.currentLogin
        ? jobs.seekers.posts.getPublished_Paged
        : jobs.seekers.posts.getPublished_Paged__NoUser;

      return apiFunction(ind.id, skip, take)
        .then(jpx => {
          let combinedItems = [...this.jobPostsExtended.items, ...jpx.items];
          this.jobPostsExtended = jpx;
          this.jobPostsExtended.items = combinedItems;
        })
        .catch(err => {
          G.log.error("seeker.discover.loadJobPosts", err);
        })
        .finally(() => {
          this.loadingMore = false;
          G.hideLoading();
          this.initializing = false;
        });
    },
    loadMore: function() {
      // if (!this.jobPostsExtended.hasNext) return;
      this.loadingMore = true;
      this.loadJobPosts(
        this.jobPostsExtended.items.length,
        this.pageSize,
        this.filter.lastUsedIndustry
      );
    },
    applyClick: function(jpx: models.jobPostExtended) {
      if (!G.data.currentLogin) {
        // No User!
        G.confirmDialog
          .open(
            `Great, let's create a profile for you, so you can apply for ${jpx.title}.`,
            "Join MeInOne",
            "Keep Browsing",
            `Create a profile`, // (${jobPost.title})
            "default",
            "success"
          )
          .then(confirm => {
            if (!confirm) return;
            this.$router.push({
              name: "Accounts_Create"
            });
            return;
          });
      } else if (!this.seekerHasVideos) {
        // No Video
        G.showSnackbar(
          "You can't apply for a job before uploading your video profile",
          "error"
        );
        return;
      } else {
        // Apply
        G.confirmDialog
          .open(
            `Apply to ${jpx.title} job, with ${jpx.organization.name} in ${jpx.city.name}?`,
            "Apply",
            "Nope",
            `Yes, APPLY`, // (${jobPost.title})
            "default",
            "success"
          )
          .then(confirm => {
            if (!confirm) return;
            G.showLoading();
            jobs.seekers.labels
              .applyForJob(jpx.id)
              .then(sl => {
                jpx.context.seeker.application = sl;
              })
              .catch(err => {
                G.log.error("Seeker.Discover.Apply", err);
              })
              .finally(() => {
                G.hideLoading();
              });
          });
      }
    },
    bookmarkClick: function(jpx: models.jobPostExtended) {
      let prm = !!jpx.context.seeker.bookmarked
        ? jobs.seekers.labels.unBookmark(jpx.id)
        : jobs.seekers.labels.bookmark(jpx.id);

      prm
        .then(sl => {
          jpx.context.seeker.bookmarked = sl;
          if (sl) G.showSnackbar(`Bookmarked`, "success");
        })
        .catch(err => {
          G.log.error("Seeker.Discover.Bookmark", err);
        })
        .finally(() => {
          // G.hideLoading();
        });
    },
    // Search
    runSearch: function() {
      // this.extraOpen = false;
      // this.seekerProfiles = { items: [] };
      // this.searchProfiles(0, this.pageSize);
    }
  }
};
